<template>
  <div>
    <el-card>
      <div class="flex flex-align-center flex-wrap">
        <div class="flex flex-align-center margin-right-20 margin-bottom-10">
          <div class="keyLabel">关键字：</div>
          <el-input v-model.trim="keywords" style="width:200px" placeholder="商品名称"></el-input>
        </div>
        <div class="flex flex-align-center margin-right-20 margin-bottom-10">
          <div class="keyLabel">活动状态：</div>
          <el-select v-model="state" style="width:200px" placeholder="活动状态">
            <el-option v-for=" v in statelist" :key="v.value" :value="v.value" :label="v.label"></el-option>
          </el-select>
        </div>
        <el-button type="primary" class="margin-bottom-10" @click="query">查询</el-button>
        <buttonPermissions datas="helpProAddEdit" class="margin-left-10">
          <el-button type="primary" class="margin-bottom-10" @click="edit({Id:0},false)">添加</el-button>
        </buttonPermissions>
      </div>
      <el-table :data="tablelist" v-loading="tableloading">
        <el-table-column prop="" label="商品" min-width="200">
          <template slot-scope="scope">
            <div class="flex flex-align-center">
              <img :src="scope.row.ImgUrlComplete" style="width:64px;height:64px;margin-right:10px" alt="">
              <div style="flex:1;width:0">
                <div class="ellipsis-two">{{scope.row.ProductName}}</div>
                <div class="font-12 ellipsis" style="color:#999999FF">{{scope.row.ProductNo}}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="活动时间" width="170">
          <template slot-scope="scope">
            <div>
              <div>{{scope.row.StartTime}}至</div>
              <div>
                {{scope.row.EndTime}}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="ActivityPrice" label="活动价"></el-table-column>
        <el-table-column prop="HelpNumber" label="助力人数"></el-table-column>
        <el-table-column prop="" label="活动状态">
          <template slot-scope="scope">
            <div>
              {{scope.row.State==0?'未开始':(scope.row.State==1?'进行中':'已结束')}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="活动订单">
          <template slot-scope="scope">
            <el-button type="text" v-if="scope.row.OrderCount" @click="toOrder(scope.row)">{{scope.row.OrderCount}}</el-button>
            <div v-else>0</div>
          </template>
        </el-table-column>
        <el-table-column prop="AddTime" label="创建时间"></el-table-column>
        <el-table-column prop="" label="操作">
          <template slot-scope="scope">
            <div class="flex flex-align-center flex-wrap" v-if="scope.row.State!=2">
              <buttonPermissions datas="helpProAddEdit" class="margin-right-10">
                <el-button type="text" @click="edit(scope.row,false)">编辑</el-button>
              </buttonPermissions>
              <buttonPermissions datas="helpProExtend" class="margin-right-10">
                <el-button type="text" @click="toextend(scope.row)">推广</el-button>
              </buttonPermissions>
              <buttonPermissions2 :datas="['helpProStop','helpProCopy']">
                <el-dropdown>
                  <span style="cursor: pointer;color: #409EFF;">
                    更多<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown" class="flex flex-column">
                    <el-dropdown-item>
                      <buttonPermissions datas="helpProStop" >
                          <el-button type="text" @click="tostop(scope.row)">使终止</el-button>
                      </buttonPermissions>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <buttonPermissions datas="helpProCopy">
                        <el-button type="text" @click="edit(scope.row,true)">复制</el-button>
                      </buttonPermissions>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </buttonPermissions2>
            </div>
            <div class="flex flex-align-center flex-wrap" v-else>
              <buttonPermissions datas="helpProAddLook" class="margin-right-10">
                <el-button type="text" @click="edit(scope.row,false)">查看</el-button>
              </buttonPermissions>
              <buttonPermissions datas="helpProCopy" class="margin-right-10">
                <el-button type="text" @click="edit(scope.row,true)">复制</el-button>
              </buttonPermissions>
              <buttonPermissions datas="helpProDel" class="margin-right-10">
                <el-button type="text" style="color:#f56c6c" @click="todel(scope.row)">删除</el-button>
              </buttonPermissions>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="margin-top-10" style="text-align:right" v-if="total">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </el-card>

    
		<batch-cods :weiCodeVisivble="weiCodeShow" :weiCodeData="weiCodeData" @cancel="weiCodeShow = false"></batch-cods>
  </div>
</template>

<script>
import buttonPermissions from '@/components/buttonPermissions';
import buttonPermissions2 from '@/components/buttonPermissions2';
import batchCods from '@/components/bathCodes';
import {
  activityHelpProactivityHelpProPageInit,
  activityHelpProactivityHelpProClose,
  activityHelpProactivityHelpProDelete,
} from "@/api/sv3.0.0"
export default {
  components:{
    buttonPermissions,
    buttonPermissions2,
    batchCods
  },
  data () {
    return {
      keywords:'',
      state:null,
      statelist:[
        {value:null,label:'全部'},
        {value:0,label:'未开始'},
        {value:1,label:'进行中'},
        {value:2,label:'已结束'},
      ],
      tablelist:[],
      tableloading:false,
      currentPage:1,
      pagesize:20,
      total:0,
      weiCodeShow:false,
      weiCodeData:{},
    }
  },
  mounted () {
    this.gettablelist()
  },
  methods: {
    toextend(row){
      this.weiCodeShow = true;
      this.weiCodeData = {
        Page: 'pageC/pages/helpPro/helpPro',
        Scene: `id=${row.ProductId}&activityId=${row.ActivityFullId}`
      }
    },
    tostop(row){
      this.$confirm('活动终止后，你将不可再对这场活动进行编辑，是否确认使终止？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '关闭',
        type: 'warning'
      }).then(async () => {
        try{
          let res = await activityHelpProactivityHelpProClose({
            ActivityFullId:row.ActivityFullId
          })
          if(res.IsSuccess){
            this.$message.success('操作成功')
          }
        }finally{
          this.gettablelist()
        }
      })
    },
    todel(row){
      this.$confirm('是否确认删除本场活动？删除后不可恢复', '提示', {
        confirmButtonText: '删除对应活动',
        cancelButtonText: '关闭',
        type: 'warning'
      }).then(async () => {
        try{
          let res = await activityHelpProactivityHelpProDelete({
            ActivityFullId:row.ActivityFullId
          })
          if(res.IsSuccess){
            this.$message.success('删除成功')
          }
        }finally{
          this.gettablelist()
        }
      })
    },
    edit(row,isCpoy){
      this.$router.push({
        path:'/market/helpPro/details',
        query:{
          Id:row.ActivityFullId,
          copy:isCpoy
        }
      })
    },
    toOrder(row){
      window.localStorage.setItem('Activity',JSON.stringify({
        ActivityId:row.ActivityFullId,
        ActivityType:16
      }))
      window.open(
        this.$router.resolve({
          path:'/order/orderMange'
        }).href, '_blank'
      )
    },
    query(){
      this.currentPage = 1
      this.gettablelist()
    },
    handleSizeChange(e){
      this.currentPage = 1
      this.pagesize = e
      this.gettablelist()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.gettablelist()
    },
    async gettablelist(){
      try{
        this.tableloading = true
        let res = await activityHelpProactivityHelpProPageInit({
          Keywrods:this.keywords,
          ActivityState:this.state,
          Skip:(this.currentPage-1)*this.pagesize,
          Take:this.pagesize
        })
        if(res.IsSuccess){
          this.tablelist = res.Result.Results
          this.total = res.Result.Total
        }
      }finally{
        this.tableloading = false
      }
    }
  }
}
</script>

<style lang='less' scoped>

</style>